<template>
  <v-img
        alt="WithU Loading"
        class="shrink mr-2"
        src="@/assets/splash-logo.gif"
        height="50"
        contain
    />
</template>

<script>

export default {
  name: "C-Check",
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    validate() {
      // console.log("VALIDATING CID FOR VISA ELIGIBILITY");
      window.parent.postMessage({
            type: 'validate-cid',
            headers: {

              'Content-Type': 'application/json'
            },

          },
          '*',

      )
      console.log("VALIDATING CID FOR VISA ELIGIBILITY");
    },
  },
  beforeMount() {
    this.validate();
  },
};
</script>

<style>
.intercom-lightweight-app {
  display: none;
}
</style>

